export const STATUSES = [
  {
    id: 'approved',
    name: 'Aprobado',
    color: 'green'
  },
  {
    id: 'decline',
    name: 'Denegado',
    color: 'volcano'
  },
  {
    id: 'rejected',
    name: 'Aprobado con Revisión',
    color: 'red'
  },
  {
    id: 'pending',
    name: 'Postulado',
    color: 'geekblue'
  },

]