export const DURATION = [
  { label: '1 Semana', value: 1 },
  { label: '2 Semanas', value: 2 },
  { label: '3 Semanas', value: 3 },
  { label: '4 Semanas', value: 4 },
  { label: '5 Semanas', value: 5 },
  { label: '6 Semanas', value: 6 },
  { label: '7 Semanas', value: 7 },
  { label: '8 Semanas', value: 8 },
  { label: '9 Semanas', value: 9 },
  { label: '10 Semanas', value: 10 },
  { label: '11 Semanas', value: 11 },
  { label: '12 Semanas', value: 12 },
  { label: '13 Semanas', value: 13 },
  { label: '14 Semanas', value: 14 },
  { label: '15 Semanas', value: 15 },
  { label: '16 Semanas', value: 16 },
  { label: '17 Semanas', value: 17 },
  { label: '18 Semanas', value: 18 },
  { label: '19 Semanas', value: 19 },
  { label: '20 Semanas', value: 20 },
  { label: '21 Semanas', value: 21 },
  { label: '22 Semanas', value: 22 },
  { label: '23 Semanas', value: 23 },
  { label: '24 Semanas', value: 24 },
  { label: '25 Semanas', value: 25 },
  { label: '26 Semanas', value: 26 },
  { label: '27 Semanas', value: 27 },
  { label: '28 Semanas', value: 28 },
  { label: '29 Semanas', value: 29 },
  { label: '30 Semanas', value: 30 },
  { label: '31 Semanas', value: 31 },
  { label: '32 Semanas', value: 32 },
  { label: '33 Semanas', value: 33 },
  { label: '34 Semanas', value: 34 },
  { label: '35 Semanas', value: 35 },
  { label: '36 Semanas', value: 36 },
  { label: '37 Semanas', value: 37 },
  { label: '38 Semanas', value: 38 },
  { label: '39 Semanas', value: 39 },
  { label: '40 Semanas', value: 40 },
  { label: '41 Semanas', value: 41 },
  { label: '42 Semanas', value: 42 },
  { label: '43 Semanas', value: 43 },
  { label: '44 Semanas', value: 44 },
  { label: '45 Semanas', value: 45 },
  { label: '46 Semanas', value: 46 },
  { label: '47 Semanas', value: 47 },
  { label: '48 Semanas', value: 48 },
  { label: '49 Semanas', value: 49 },
  { label: '50 Semanas', value: 50 },
  { label: '51 Semanas', value: 51 },
  { label: '52 Semanas', value: 52 },
]